.call-now {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.call-now a {
  color: #007BFF; /* Maintain the same color as the surrounding text */
  text-decoration: none; /* Remove underline */
}

.call-now a:hover {
  text-decoration: underline; /* Underline the link on hover */
}

@media only screen and (max-width: 768px) {
  .call-now {
    font-size: 20px;
  }
}
