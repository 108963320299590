.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Ensure the modal itself is scrollable */
}

.modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  max-width: 90%; /* Adjust the max-width as desired */
  width: 85%; /* Ensure full width on smaller screens */
  max-height: 80%;
  overflow-y: auto; /* Enable scrolling within the modal content */
  position: relative;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-size: 15px;
  cursor: default;
}

.modal-close {
  position: absolute;
  padding: 10px;
  top: 10px;
  right: 30px;
  font-size: 45px;
  cursor: pointer !important; /* Add this line */
  z-index: 999; /* Ensure the close button appears above other elements */
}

.modal-slideshow {
  position: relative;
  overflow: hidden;
}

.modal-slide {
  width: 100%;
  max-height: 65vh; /* Adjust max-height to fit within the viewport */
  object-fit: contain; /* Ensure the image covers the container without being distorted */
  border-radius: 8px;
  margin: 0 auto;
  display: block;
}

.modal-prev, .modal-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 30px;
  z-index: 1;
}

.modal-prev {
  left: 10px;
}

.modal-next {
  right: 10px;
}

.modal-details {
  margin-top: 20px; /* Add margin */
}

.calendar-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Add some top margin for spacing */
}

.submit-button-container {
  text-align: center; /* Center the button */
  margin-top: 20px; /* Add some top margin for spacing */
}

.submit-button {
  background-color: #4777d7; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: white; /* Darker green on hover */
  color: #4777d7;
  border: 1px solid #4777d7;
}
