.courts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  padding: 20px;
  justify-content: center;
}

.court {
  text-align: center;
}

.court-image {
  width: 100%;
  height: auto;
  max-height: 300px; /* Ensure images don't become too large */
  border-radius: 8px;
  object-fit: cover; /* Ensure the image covers the element's content box */
  transition: transform 0.1s ease-in-out;
}

.court-image:hover {
  transform: scale(1.05); /* Slightly enlarge the image on hover for a nice effect */
  cursor: pointer;
}

.court-name {
  margin-top: 10px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-size: 17px;
  word-wrap: break-word;
}

.court-desc {
  margin: 10px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-size: 12px;
  word-wrap: break-word;
}

.submit-button {
  background-color: #4777d7; /* Blue */
  border: 1px solid #4777d7; /* Consistent border size */
  color: white !important; /* Keep the text color white */
  padding: 10px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 15px !important;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  height: 50px; /* Explicit height to prevent layout shift */
  line-height: 30px; /* Align text vertically within the button */
  font-weight: bold;
}

.submit-button:hover {
  background-color: #355bb5 !important; /* Darker shade of blue on hover */
  border-color: #355bb5 !important; /* Ensure border color matches the hover state */
  cursor: pointer;
}


/* Add styles for the ContactUs component */
.contact-us-container {
  text-align: center;
  margin-top: 20px;
}

.contact-us-container h2 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.contact-us-button {
  text-decoration: underline;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}
