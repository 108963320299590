.find-courts-page {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    color: #333;
    line-height: 1.6;
    padding: 20px;
}

.header {
    text-align: center;
    margin-bottom: 40px;
}

.header h1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.header p {
    font-size: 18px;
    color: #555;
}

.court-search {
    margin-bottom: 40px;
    text-align: center;
}

.search-form {
    display: inline-block;
    width: 100%;
    max-width: 600px;
}

.search-form input[type="text"] {
    width: 70%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
}

.search-form button {
    width: 30%;
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
}

.search-form button:hover {
    background-color: #0056b3;
}

.featured-courts {
    margin-bottom: 40px;
}

.featured-courts h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 26px;
}

.courts-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.court-item {
    width: 300px;
    margin-bottom: 20px;
    text-align: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.court-item img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.court-item h3 {
    font-size: 20px;
    margin-top: 15px;
}

.court-item p {
    font-size: 14px;
    color: #777;
    margin: 15px 0;
}

.court-item button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.court-item button:hover {
    background-color: #0056b3;
}

.reviews {
    margin-bottom: 40px;
    text-align: center;
}

.reviews h2 {
    margin-bottom: 20px;
    font-size: 26px;
}

.review-item {
    margin-bottom: 20px;
    font-style: italic;
    color: #555;
}

.footer {
    text-align: center;
    margin-top: 40px;
    font-size: 14px;
    color: #777;
}

.footer p {
    margin: 5px 0;
}

.footer a {
    color: #007BFF;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}
