.navbar {
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.logo h1 {
  margin: 0;
  font-size: 40px; /* Adjust font size as needed */
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

.logo h1:hover {
  cursor: pointer !important;
}