/* General reset to avoid overflow issues */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.court-details {
    max-width: 1120px;
    margin: 0 auto;
    padding: 24px;
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
}

.court-details2 {
    max-width: 1120px;
    margin: 0 auto;
    padding: 24px;
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
}

.court-title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 8px;
}

.court-rating {
    font-size: 14px;
    margin-bottom: 24px;
}

.star {
    color: #FF385C;
}

.court-gallery {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 8px;
    margin-bottom: 48px;
}

.main-image-container {
    position: relative;
}

.main-image {
    width: 100%;
    height: 300px; /* Set a specific height */
    object-fit: cover;
    border-radius: 12px 0 0 12px;
    cursor: pointer; /* Add cursor pointer */
}

.popup-button {
    position: absolute;
    bottom: 15px;
    left: 20px;
    background-color: white;
    color: black;
    border: none;
    padding: 10px 15px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
}

.popup-button:hover {
    background-color: #f2f2f2;
}

.fullscreen {
    margin-left: 5px;
}


.small-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.small-images img {
    width: 100%;
    height: 150px; /* Set a specific height for small images */
    object-fit: cover;
    cursor: pointer; /* Add cursor pointer */
}

.small-images img:nth-child(2) {
    border-radius: 0 12px 0 0;
}

.small-images img:nth-child(4) {
    border-radius: 0 0 12px 0;
}

.court-info-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 80px;
}

.court-info h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 24px;
}

.court-features {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
}

.court-features span {
    font-size: 16px;
}

.court-details {
    border-top: 1px solid #DDDDDD;
    padding-top: 24px;
}

.detail-item {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
}

.icon {
    font-size: 24px;
}

.detail-item strong {
    display: block;
    margin-bottom: 4px;
}

.detail-item p {
    color: #717171;
    font-size: 14px;
}

.booking-form {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 6px 16px rgba(0,0,0,0.12);
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    overflow: hidden; /* Ensure no scrolling within popup */
}

.popup img {
    max-width: 80%;
    max-height: 80%;
    margin: 20px;
    border-radius: 10px;
}

.popup-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: space-between;
    width: 100px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: black;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

.nav-button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.back-button {
    left: 10px;
}

.forward-button {
    right: 10px;
}

@media only screen and (max-width: 768px) {
    .court-gallery {
        grid-template-columns: 1fr;
    }

    .small-images {
        display: none;
    }

    .main-image {
        height: auto; /* Adjust height for mobile */
        border-radius: 12px;
    }

    .court-info-container {
        grid-template-columns: 1fr;
    }

    .booking-form {
        margin-top: 20px;
    }
}
