.form-container {
  padding: 20px;
  /* border: 1px solid #4777d7; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children elements horizontally */
}

.form-container h2 {
  justify-content: center;
  text-align: center;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children elements horizontally */
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-align: center; /* Center text */
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="number"],
.form-group select {
  width: 130%;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  text-align: center; /* Center text */
}

.form-group select {
  appearance: none; /* Remove default arrow icon for select */
  border: 1px solid #4777d7;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3E%3C/svg%3E") no-repeat;
  background-position: right 10px center; /* Position the arrow icon on the right */
  background-size: 20px;
}

.form-group button[type="submit"] {
  background-color: #4777d7; /* Green */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  border: 1px solid transparent; /* Ensure consistent border size */
  transition: background-color 0.2s; 
  text-align: center; /* Center text */
}

.form-group button[type="submit"]:hover {
  background-color: #355bb5 !important; /* Darker shade of blue on hover */
  border-color: #355bb5 !important; /* Ensure border color matches the hover state */
}

.success-message {
  background-color: #d4edda; /* Light green background color */
  color: #155724; /* Dark green text color */
  border: 1px solid #c3e6cb; /* Light green border */
  padding: 10px 20px; /* Padding around the message */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 20px; /* Bottom margin to separate from other content */
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  text-align: center; /* Center text */
}

.form-group2 button[type="submit"] {
  background-color: #4777d7; /* Green */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  border: 1px solid transparent; /* Ensure consistent border size */
  transition: background-color 0.3s; 
  text-align: center; /* Center text */
}